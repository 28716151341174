import cacheApi from '@/core/cacheApi'

import { useLanguage } from '@/composables/language'
import type { ApiErrorResponse, ApiResponse } from '@/types/apiResponse'
import type { Attribute } from '@/types/attribute'
import { handleError } from '@/helpers/handleError'

const { mainLang } = useLanguage()
export async function getAllAttributes(): Promise<ApiResponse> {
  try {
    const { data } = await cacheApi.get(`/wp-json/wc/v3/products/attributes?lang=${mainLang}`)
    return {
      success: true,
      data: data.map((attribute: Attribute) => ({
        id: attribute.id,
        name: attribute.name,
        slug: attribute.slug,
        menu_order: attribute.menu_order
      }))
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
